/* === Constants =================================================== */

$responsive-large-breakpoint: 1024px !default;
$responsive-medium-breakpoint: 768px !default;

/* === Media sizes ================================================= */

/* --- Large devices ----------------------------------------------- */
@media only screen and (min-width: $responsive-large-breakpoint) {
    .only_desktop, .not_mobile {
        display: block;
    }
    .not_desktop, .only_mobile {
        display: none;
    }
    .only_tablet {
        display: none;
    }
    .not_tablet {
        display: block;
    }
    .only_phone {
        display: none;
    }
    .not_phone {
        display: block;
    }
}

/* --- Large and medium devices ------------------------------------ */
@media only screen and (min-width: $responsive-medium-breakpoint) {
}

/* --- Medium devices ---------------------------------------------- */
@media only screen and (min-width: $responsive-medium-breakpoint) and (max-width: $responsive-large-breakpoint - 1px) {
    .only_desktop, .not_mobile {
        display: none;
    }
    .not_desktop, .only_mobile {
        display: block;
    }
    .only_tablet {
        display: block;
    }
    .not_tablet {
        display: none;
    }
    .only_phone {
        display: none;
    }
    .not_phone {
        display: block;
    }
}

/* --- Medium and small devices ------------------------------------ */
@media only screen and (max-width: $responsive-large-breakpoint - 1px) {

}

/* --- Small devices ----------------------------------------------- */
@media only screen and (max-width: $responsive-medium-breakpoint - 1px) {
    .only_desktop, .not_mobile {
        display: none;
    }
    .not_desktop, .only_mobile {
        display: block;
    }
    .only_tablet {
        display: none;
    }
    .not_tablet {
        display: block;
    }
    .only_phone {
        display: block;
    }
    .not_phone {
        display: none;
    }
    
}
