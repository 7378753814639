// --------------------------------------------------------------------
//
// Collapsible content styling
// 
// --------------------------------------------------------------------

/* === Constants ================================================== */

$collapsible-content-max-height: 768px !default;


/* === Collapsible content ======================================== */

input.collapsible_toggle {
    display: none;
}

input.collapsible_toggle + label {
    display: block;
}

input.collapsible_toggle + label > span {
    display: block;
}

input.collapsible_toggle + label > span::before {
    display: inline-block;
    margin: 0 0.5em 0 0.25em;
    content: "\25b6";
    transform: translateY(-0.2em);
    transition: transform 200ms ease-out;
}

input.collapsible_toggle:checked + label > span::before {
    transform: rotate(90deg);
}

input.collapsible_toggle + label + .collapsible {
    overflow: hidden;
    max-height: 0;
    transition: max-height 300ms ease-in-out;
}

input.collapsible_toggle:checked + label + .collapsible {
    max-height: $collapsible-content-max-height;
}
