/** 
 * main.scss
 * 
 * - Constants
 * - Default imports
 * - Fonts
 * - General HTML tags
 * - Helper classes
 * - Page layout
 * - RichText-Editor
 * - Accessibility helpers
 * - Responsive-related stuff (imported)
 * 
 */


/* === Constants =================================================== */

$image-dir: "../images/";
$fonts-dir: "../fonts/";

$page-width: 878px;

$responsive-large-breakpoint: 900px;
$responsive-medium-breakpoint: 640px;

/* === Default imports ============================================= */

@import 'defaults/responsive.scss';
@import 'defaults/collapsible.scss';
@import 'defaults/mobile_menu.scss';


/* === Fonts ======================================================= */

/* open-sans-regular - latin */
@font-face {
	font-family: "Open Sans";
  	font-style: normal;
  	font-weight: 400;
  	src: local("Open Sans Regular"), local("OpenSans-Regular"),
       	url($fonts-dir + "open-sans-v17-latin-regular.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       	url($fonts-dir + "open-sans-v17-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-italic - latin */
@font-face {
  	font-family: "Open Sans";
  	font-style: italic;
  	font-weight: 400;
  	src: local("Open Sans Italic"), local("OpenSans-Italic"),
       	url($fonts-dir + "open-sans-v17-latin-italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       	url($fonts-dir + "open-sans-v17-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
  	font-family: "Open Sans";
  	font-style: normal;
  	font-weight: 700;
  	src: local("Open Sans Bold"), local("OpenSans-Bold"),
       	url($fonts-dir + "open-sans-v17-latin-700.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       	url($fonts-dir + "open-sans-v17-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700italic - latin */
@font-face {
  	font-family: "Open Sans";
  	font-style: italic;
  	font-weight: 700;
  	src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"),
       	url($fonts-dir + "open-sans-v17-latin-700italic.woff2") format("woff2"), /* Chrome 26+, Opera 23+, Firefox 39+ */
       	url($fonts-dir + "open-sans-v17-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* libre-baskerville-regular - latin */
@font-face {
	font-family: 'Libre Baskerville';
	font-style: normal;
	font-weight: 400;
	src: local('Libre Baskerville'), local('LibreBaskerville-Regular'),
		url('../fonts/libre-baskerville-v7-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('../fonts/libre-baskerville-v7-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  
  /* libre-baskerville-italic - latin */
  @font-face {
	font-family: 'Libre Baskerville';
	font-style: italic;
	font-weight: 400;
	src: local('Libre Baskerville Italic'), local('LibreBaskerville-Italic'),
		url('../fonts/libre-baskerville-v7-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
		url('../fonts/libre-baskerville-v7-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  

/* === General HTML tags =========================================== */

* {
	box-sizing: border-box; 
}

html  {
	height: 100%;
	font-family: "Arial", "Open Sans";
	font-size: 12px;
	line-height: 1.4;
}

body {
	margin: 0 auto; 
	padding: 0;
    height: 100%;
    background-color: #000000;
    background-image: url($image-dir + "bg_body.gif");
    background-position: top;
    background-repeat: repeat-x;
}

p, table, blockquote {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
}

ul {
	padding-left: 2em;
	line-height: 1.3;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0.5em;
    padding-top: 0;
    padding-bottom: 0;
}

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
    margin-top: 0;
}

h1 {
}

h2 {
}

h3 {
}

a img {
    border: 0;
}

a, a:visited {
	text-decoration: none;
}

a:hover, a:active {
}

pre {
	font-size:0.7rem;
	line-height: 1.2;
}

/* === Helper classes ============================================== */

/* Ensured the container is at least as high as
 * any floats it might contain.
 */
.container {
    overflow: hidden;
}
.container:after {
    content: "";
    display: table;
    clear: both;
}

/* --- Navigation in general --------------------------------------- */

nav ul {
	display: flex;
	flex-flow: row wrap;
	margin: 0;
	padding: 0;
	list-style: none;
}

nav li {
}

/* === Page layout ================================================= */

#overlay {
    position: fixed;
    display: none;
    left: 0;
    top: 0;
    z-index: 500;
    background-color: rgba(1, 1, 1, 0.75);
    left: 0;
}

#page {
    margin: 0 auto 0;
    padding: 0 10px 17px;
	width: $page-width;
	display: grid;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto auto auto 1fr auto;
	grid-template-areas:
		"header header header"
	    "topnav topnav topnav" 
		"left main quote"
		"left main right"
        "footer footer footer";
    background-color: #535353;
    background-image: url($image-dir + "bg_outline.gif");
    background-position: top;
    background-repeat: repeat-x;
}

/* --- Header ------------------------------------------------------ */

header {
	grid-area: header;
    display: grid;
    height: 109px;
	grid-template-columns: auto 1fr auto;
	grid-template-rows: auto 1fr;
	grid-template-areas: 
		"logo spacer hamburger"
		"logo header_nav header_nav";
    background-color: #ffffff;
    background-image: url($image-dir + "bg_header.gif");
    background-position: top;
    background-repeat: repeat-x;
}

#logo {
    grid-area: logo;
    margin-top: 6px;
    padding: 24px 0 0 28px;
    background-color: #ffffff;
}

#header_nav {
	grid-area: header_nav;
	margin: 68px 32px 0 0;
	font-size: 14px;
	font-weight: bold;
}

#header_nav ul {
	justify-content: flex-end;
}

#header_nav li {
	margin-left: 15px;
}

#header_nav a, #header_nav a:visited {
	color: #000000;
}

#hamburger {
	grid-area: hamburger;
}

/* --- Top navigation ---------------------------------------------- */

#topnav {
    grid-area: topnav;
    padding: 0 30px;
    background-image: url($image-dir + "bg_Menu.gif");
    background-position: top;
	background-repeat: repeat-x;
	font-size: 14px;
}

#topnav a, #topnav a:visited {
	color: #ffffff;
}

#topnav ul {
	padding-left: 23px;
    height: 93px;
    background-image: url($image-dir + "bg_nav1.gif");
    background-position: top;
    background-repeat: repeat-x;
}

#topnav li {
	padding-top: 48px;
	margin-right: 16px;
}

#topnav li.current {
    background-image: url($image-dir + "bg_nav1_active.gif");
    background-position: center top;
    background-repeat: no-repeat;
}

#topnav li.current a, #topnav li.current a:visited {
	color: #0094de;
}


/* --- Left sidebar content ---------------------------------------- */

aside#left {
    grid-area: left;
	padding: 48px 0 0 30px;
	width: 186px;
    background-color: #81817b;
}

aside#left p {
	margin: 4px 0 8px;
}

/* --- Main content ------------------------------------------------ */

main {
	grid-area: main;
	padding: 9px 14px 20px;
    min-height: 480px;
	background-color: #ffffff;
	line-height: 1.3;
}

main a, main a:visited {
	color: #000000;
	text-decoration: underline;
}

main a.home {
	display: inline-block;
	margin-bottom: 0.5em;
}

main h1 {
	margin-top: 0;
}

main p {
	margin: 1em 0;
}

main img {
	max-width: 100%;
}

/* --- Quote of the day -------------------------------------------- */

aside#quote {
	grid-area: quote;
	width: 212px;
    padding: 0 39px 0 0;
    background-color: #ffffff;
    background-image: url($image-dir + "bg_right.png");
    background-position: right;
    background-repeat: repeat-y;
}

aside#quote blockquote {
	margin-left: 10px;
	padding: 12px 6px 12px 14px;
	background-color: #ffc600;
	font-family: "Georgia", "Libre Baskerville";
	font-size: 13px;
	line-height: 1.3;
}


/* --- Right sidebar content --------------------------------------- */

aside#right {
	grid-area: right;
	width: 212px;
    padding: 0 39px 26px 0;
    background-color: #ffffff;
    background-image: url($image-dir + "bg_right.png");
    background-position: right;
    background-repeat: repeat-y;
}

aside#right > label {
	color: #ffffff;
	background-color: #0094de;
}

aside#right > label > span {
	overflow: hidden;
}

aside#right h2 {
	margin: 0 0 11px;
	padding: 14px 6px 14px 15px;
	font-size: 14px;
	color: #ffffff;
	background-color: #0094de;
	font-weight: normal;
}

aside#right p {
	margin: 0 4px 0 13px;
}

aside#right a, aside#right a:visited {
	display: block;
	margin: 6px 2px 3px 0;
	text-align: right;
	color: #ff0000;
}

/* --- Footer ------------------------------------------------------ */

footer {
    grid-area: footer;
    display: flex;
    padding: 0 30px;
	flex-direction: row;
    background-color: #535353;
    background-image: url($image-dir + "bg-footer1.gif");
    background-position: top;
	background-repeat: repeat-x;
	color: #ffffff;
	font-size: 12px;
}

#footer_nav {
    width: 100%;
    height: 76px;
    background-color: #B0B0B0;
    background-image: url($image-dir + "bg-footer1.gif");
    background-position: top;
    background-repeat: repeat-x;
}

#footer_nav ul {
	margin-top: 15px;
	justify-content: center;
}

#footer_nav li::before {
	content: "|\a0";
}
#footer_nav li:first-child::before {
	content: "";
}
#footer_nav a, #footer_nav a:visited {
	color: #ffffff;
}
#footer_nav li::after {
	content: "\a0";
}


/* === Accessibility helpers ======================================= */

/* Hide visually, but remain approachable for screenreader */

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	white-space: nowrap;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	border: 0;
}

/* Show bypass link on hover */

.element-focusable:focus {
	clip: auto;
	overflow: visible;
	height: auto;
}

/* Sample styling for bypass link */

.bypass-to-main:focus {
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: center;
	background: #333;
	color: #fff;
}


/* --- Include responsive styles ----------------------------------- */
@import 'mobile.scss';
