// --------------------------------------------------------------------
//
// Mobile drawer menu styling
// 
// --------------------------------------------------------------------

/* === Constants =================================================== */

$hamburger-color: #000000 !default;
$hamburger-background-color: #ffffff !default;

$drawer-border-color: #c0c0c0 !default;
$drawer-background-color: #ffffff !default;
$drawer-link-font-weight: bold !default;
$drawer-link-color: #000000 !default;
$drawer-link-text-decoration: none !default;
$drawer-link-hover-color: #404040 !default;
$drawer-link-hover-text-decoration: underline !default;

$drawer-current-link-font-weight: bold !default;
$drawer-current-link-color: #000000 !default;
$drawer-current-link-text-decoration: none !default;
$drawer-current-link-hover-color: #404040 !default;
$drawer-current-link-hover-text-decoration: underline !default;

$drawer-active-background-color: #c0d0ff !default;
$drawer-active-link-font-weight: bold !default;
$drawer-active-link-color: #000000 !default;
$drawer-active-link-text-decoration: none !default;
$drawer-active-link-hover-color: #404040 !default;
$drawer-active-link-hover-text-decoration: underline !default;

/* === Mobile menu ================================================= */

/* --- Hamburger --------------------------------------------------- */

#hamburger {
    margin: 8px;
    padding: 8px;
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background-color: $hamburger-background-color;
}

#hamburger > ul {
    display: none;
}

#hamburger div {
    height: 20%;
    background-color: $hamburger-color;
}

#hamburger div:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

#hamburger div:nth-child(2) {
    margin: 20% 0;
}

#hamburger div:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}


/* --- Drawer menu ------------------------------------------------- */

.drawer {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    width: 0;
    max-width: 480px;
}
.left.drawer {
    left: 0;
}
.right.drawer {
    right: 0;
}

.drawer * {
    box-sizing: border-box;
}

.drawer_opened .drawer {
    min-width: 240px;
    width: 75vw;
}

/* --- Mobile menu pages --- */
#drawer_menu {
    position: fixed;
    top: 0;
    height: 100%;
    background-color: $drawer-background-color;
    transition: width 300ms ease-out;
    overflow-x: hidden;
    overflow-y: auto;
}
#drawer_menu.left {
    left: -1px;
    border-right: 1px solid $drawer-border-color;
}
#drawer_menu.right {
    right: 1px;
    border-left: 1px solid $drawer-border-color;
}

#drawer_menu .menu_page {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    top: 0;
    background-color: $drawer-background-color;
}
#drawer_menu.left .menu_page {
    left: -100%;
    border-left: 1px solid $drawer-border-color;
}
#drawer_menu.right .menu_page {
    right: -100%;
    border-right: 1px solid $drawer-border-color;
}

.animate #drawer_menu.left .menu_page {
    transition: left 300ms ease-out;
}
.animate #drawer_menu.right .menu_page {
    transition: right 300ms ease-out;
}

#drawer_menu .menu_page.above {
    left: -100%;
    z-index: 99;
}

#drawer_menu .menu_page.focussed {
    left: 0;
    z-index: 100;
}

#drawer_menu .menu_page.below {
    left: 100%;
    z-index: 99;
}

#drawer_menu .menu_page li {
    clear: both;
    margin: 0;
    border-bottom: 1px solid $drawer-border-color;
}

#drawer_menu .menu_page li.active {
    background-color: $drawer-active-background-color;
}

#drawer_menu .menu_page a {
    display: block;
    padding: 12px;
}

#drawer_menu .menu_page .close {
    float: right;
    margin: 0;
    border-left: 1px solid $drawer-border-color;
    width: 3em;
    text-align: center;
}

#drawer_menu .menu_page .back {
    float: left;
    margin: 0;
    width: 3em;
    border-right: 1px solid $drawer-border-color;
    text-align: center;
}

#drawer_menu .menu_page .current {
    display: block;
    padding-left: 3.5em;
    font-weight: bold;
}

#drawer_menu li.current a, #drawer_menu li.current a:visited {
	color: $drawer-current-link-color;
	font-weight: $drawer-current-link-font-weight;
	text-decoration: $drawer-current-link-text-decoration;
}
#drawer_menu li.current a:hover, #drawer_menu li.current a:active {
	color: $drawer-current-link-hover-color;
	text-decoration: $drawer-current-link-hover-text-decoration;
}

#drawer_menu .menu_page .child {
    display: block;
    padding-left: 3.5em;
}

#drawer_menu .menu_page .more {
    float: right;
    margin: 0;
    border-left: 1px solid $drawer-border-color;
    width: 3em;
    text-align: center;
}

#drawer_menu li a, #drawer_menu li a:visited {
	color: $drawer-link-color;
	font-weight: $drawer-link-font-weight;
	text-decoration: $drawer-link-text-decoration;
}
#drawer_menu li a:hover, #drawer_menu li a:active {
	color: $drawer-link-hover-color;
	text-decoration: $drawer-link-hover-text-decoration;
}
#drawer_menu li.active a, #drawer_menu li.active a:visited {
	color: $drawer-active-link-color;
	font-weight: $drawer-active-link-font-weight;
	text-decoration: $drawer-active-link-text-decoration;
}
#drawer_menu li.active a:hover, #drawer_menu li.active a:active {
	color: $drawer-active-link-hover-color;
	text-decoration: $drawer-active-link-hover-text-decoration;
}


/* --- Page content wrapper --- */
#page_wrapper {
    position: relative;
    min-height: 100vh;
    transform: 0;
    transition: transform 300ms ease-out;
    // overflow-x: hidden; <-- wird jetzt im JS beim Ausklappen des Menues gesetzt, weil es das Anspringen von Anchors in FF verhindert.
    z-index: 200;
}

/* --- Overlay --- */
.drawer_opened #overlay {
    display: block;
    background-color: rgba(1, 1, 1, 0);
}
