/** 
 * main.scss
 * 
 * - Constants
 * - Default imports
 * - Fonts
 * - General HTML tags
 * - Helper classes
 * - Page layout
 * - RichText-Editor
 * - Accessibility helpers
 * - Responsive-related stuff (imported)
 * 
 */
/* === Constants =================================================== */
/* === Default imports ============================================= */
/* === Constants =================================================== */
/* === Media sizes ================================================= */
/* --- Large devices ----------------------------------------------- */
@media only screen and (min-width: 900px) {
  .only_desktop, .not_mobile {
    display: block; }
  .not_desktop, .only_mobile {
    display: none; }
  .only_tablet {
    display: none; }
  .not_tablet {
    display: block; }
  .only_phone {
    display: none; }
  .not_phone {
    display: block; } }

/* --- Large and medium devices ------------------------------------ */
/* --- Medium devices ---------------------------------------------- */
@media only screen and (min-width: 640px) and (max-width: 899px) {
  .only_desktop, .not_mobile {
    display: none; }
  .not_desktop, .only_mobile {
    display: block; }
  .only_tablet {
    display: block; }
  .not_tablet {
    display: none; }
  .only_phone {
    display: none; }
  .not_phone {
    display: block; } }

/* --- Medium and small devices ------------------------------------ */
/* --- Small devices ----------------------------------------------- */
@media only screen and (max-width: 639px) {
  .only_desktop, .not_mobile {
    display: none; }
  .not_desktop, .only_mobile {
    display: block; }
  .only_tablet {
    display: none; }
  .not_tablet {
    display: block; }
  .only_phone {
    display: block; }
  .not_phone {
    display: none; } }

/* === Constants ================================================== */
/* === Collapsible content ======================================== */
input.collapsible_toggle {
  display: none; }

input.collapsible_toggle + label {
  display: block; }

input.collapsible_toggle + label > span {
  display: block; }

input.collapsible_toggle + label > span::before {
  display: inline-block;
  margin: 0 0.5em 0 0.25em;
  content: "\25b6";
  transform: translateY(-0.2em);
  transition: transform 200ms ease-out; }

input.collapsible_toggle:checked + label > span::before {
  transform: rotate(90deg); }

input.collapsible_toggle + label + .collapsible {
  overflow: hidden;
  max-height: 0;
  transition: max-height 300ms ease-in-out; }

input.collapsible_toggle:checked + label + .collapsible {
  max-height: 768px; }

/* === Constants =================================================== */
/* === Mobile menu ================================================= */
/* --- Hamburger --------------------------------------------------- */
#hamburger {
  margin: 8px;
  padding: 8px;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: #ffffff; }

#hamburger > ul {
  display: none; }

#hamburger div {
  height: 20%;
  background-color: #000000; }

#hamburger div:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px; }

#hamburger div:nth-child(2) {
  margin: 20% 0; }

#hamburger div:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

/* --- Drawer menu ------------------------------------------------- */
.drawer {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  width: 0;
  max-width: 480px; }

.left.drawer {
  left: 0; }

.right.drawer {
  right: 0; }

.drawer * {
  box-sizing: border-box; }

.drawer_opened .drawer {
  min-width: 240px;
  width: 75vw; }

/* --- Mobile menu pages --- */
#drawer_menu {
  position: fixed;
  top: 0;
  height: 100%;
  background-color: #ffffff;
  transition: width 300ms ease-out;
  overflow-x: hidden;
  overflow-y: auto; }

#drawer_menu.left {
  left: -1px;
  border-right: 1px solid #c0c0c0; }

#drawer_menu.right {
  right: 1px;
  border-left: 1px solid #c0c0c0; }

#drawer_menu .menu_page {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  top: 0;
  background-color: #ffffff; }

#drawer_menu.left .menu_page {
  left: -100%;
  border-left: 1px solid #c0c0c0; }

#drawer_menu.right .menu_page {
  right: -100%;
  border-right: 1px solid #c0c0c0; }

.animate #drawer_menu.left .menu_page {
  transition: left 300ms ease-out; }

.animate #drawer_menu.right .menu_page {
  transition: right 300ms ease-out; }

#drawer_menu .menu_page.above {
  left: -100%;
  z-index: 99; }

#drawer_menu .menu_page.focussed {
  left: 0;
  z-index: 100; }

#drawer_menu .menu_page.below {
  left: 100%;
  z-index: 99; }

#drawer_menu .menu_page li {
  clear: both;
  margin: 0;
  border-bottom: 1px solid #c0c0c0; }

#drawer_menu .menu_page li.active {
  background-color: #c0d0ff; }

#drawer_menu .menu_page a {
  display: block;
  padding: 12px; }

#drawer_menu .menu_page .close {
  float: right;
  margin: 0;
  border-left: 1px solid #c0c0c0;
  width: 3em;
  text-align: center; }

#drawer_menu .menu_page .back {
  float: left;
  margin: 0;
  width: 3em;
  border-right: 1px solid #c0c0c0;
  text-align: center; }

#drawer_menu .menu_page .current {
  display: block;
  padding-left: 3.5em;
  font-weight: bold; }

#drawer_menu li.current a, #drawer_menu li.current a:visited {
  color: #000000;
  font-weight: bold;
  text-decoration: none; }

#drawer_menu li.current a:hover, #drawer_menu li.current a:active {
  color: #404040;
  text-decoration: underline; }

#drawer_menu .menu_page .child {
  display: block;
  padding-left: 3.5em; }

#drawer_menu .menu_page .more {
  float: right;
  margin: 0;
  border-left: 1px solid #c0c0c0;
  width: 3em;
  text-align: center; }

#drawer_menu li a, #drawer_menu li a:visited {
  color: #000000;
  font-weight: bold;
  text-decoration: none; }

#drawer_menu li a:hover, #drawer_menu li a:active {
  color: #404040;
  text-decoration: underline; }

#drawer_menu li.active a, #drawer_menu li.active a:visited {
  color: #000000;
  font-weight: bold;
  text-decoration: none; }

#drawer_menu li.active a:hover, #drawer_menu li.active a:active {
  color: #404040;
  text-decoration: underline; }

/* --- Page content wrapper --- */
#page_wrapper {
  position: relative;
  min-height: 100vh;
  transform: 0;
  transition: transform 300ms ease-out;
  z-index: 200; }

/* --- Overlay --- */
.drawer_opened #overlay {
  display: block;
  background-color: rgba(1, 1, 1, 0); }

/* === Fonts ======================================================= */
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/open-sans-v17-latin-regular.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../fonts/open-sans-v17-latin-italic.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans-v17-latin-700.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* open-sans-700italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("../fonts/open-sans-v17-latin-700italic.woff2") format("woff2"), url("../fonts/open-sans-v17-latin-700italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* libre-baskerville-regular - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: local("Libre Baskerville"), local("LibreBaskerville-Regular"), url("../fonts/libre-baskerville-v7-latin-regular.woff2") format("woff2"), url("../fonts/libre-baskerville-v7-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* libre-baskerville-italic - latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 400;
  src: local("Libre Baskerville Italic"), local("LibreBaskerville-Italic"), url("../fonts/libre-baskerville-v7-latin-italic.woff2") format("woff2"), url("../fonts/libre-baskerville-v7-latin-italic.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* === General HTML tags =========================================== */
* {
  box-sizing: border-box; }

html {
  height: 100%;
  font-family: "Arial", "Open Sans";
  font-size: 12px;
  line-height: 1.4; }

body {
  margin: 0 auto;
  padding: 0;
  height: 100%;
  background-color: #000000;
  background-image: url("../images/bg_body.gif");
  background-position: top;
  background-repeat: repeat-x; }

p, table, blockquote {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0; }

ul {
  padding-left: 2em;
  line-height: 1.3; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0.5em;
  padding-top: 0;
  padding-bottom: 0; }

h1:first-child, h2:first-child, h3:first-child, h4:first-child, h5:first-child, h6:first-child {
  margin-top: 0; }

a img {
  border: 0; }

a, a:visited {
  text-decoration: none; }

pre {
  font-size: 0.7rem;
  line-height: 1.2; }

/* === Helper classes ============================================== */
/* Ensured the container is at least as high as
 * any floats it might contain.
 */
.container {
  overflow: hidden; }

.container:after {
  content: "";
  display: table;
  clear: both; }

/* --- Navigation in general --------------------------------------- */
nav ul {
  display: flex;
  flex-flow: row wrap;
  margin: 0;
  padding: 0;
  list-style: none; }

/* === Page layout ================================================= */
#overlay {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  z-index: 500;
  background-color: rgba(1, 1, 1, 0.75);
  left: 0; }

#page {
  margin: 0 auto 0;
  padding: 0 10px 17px;
  width: 878px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto auto auto 1fr auto;
  grid-template-areas: "header header header" "topnav topnav topnav"  "left main quote" "left main right" "footer footer footer";
  background-color: #535353;
  background-image: url("../images/bg_outline.gif");
  background-position: top;
  background-repeat: repeat-x; }

/* --- Header ------------------------------------------------------ */
header {
  grid-area: header;
  display: grid;
  height: 109px;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-template-areas: "logo spacer hamburger" "logo header_nav header_nav";
  background-color: #ffffff;
  background-image: url("../images/bg_header.gif");
  background-position: top;
  background-repeat: repeat-x; }

#logo {
  grid-area: logo;
  margin-top: 6px;
  padding: 24px 0 0 28px;
  background-color: #ffffff; }

#header_nav {
  grid-area: header_nav;
  margin: 68px 32px 0 0;
  font-size: 14px;
  font-weight: bold; }

#header_nav ul {
  justify-content: flex-end; }

#header_nav li {
  margin-left: 15px; }

#header_nav a, #header_nav a:visited {
  color: #000000; }

#hamburger {
  grid-area: hamburger; }

/* --- Top navigation ---------------------------------------------- */
#topnav {
  grid-area: topnav;
  padding: 0 30px;
  background-image: url("../images/bg_Menu.gif");
  background-position: top;
  background-repeat: repeat-x;
  font-size: 14px; }

#topnav a, #topnav a:visited {
  color: #ffffff; }

#topnav ul {
  padding-left: 23px;
  height: 93px;
  background-image: url("../images/bg_nav1.gif");
  background-position: top;
  background-repeat: repeat-x; }

#topnav li {
  padding-top: 48px;
  margin-right: 16px; }

#topnav li.current {
  background-image: url("../images/bg_nav1_active.gif");
  background-position: center top;
  background-repeat: no-repeat; }

#topnav li.current a, #topnav li.current a:visited {
  color: #0094de; }

/* --- Left sidebar content ---------------------------------------- */
aside#left {
  grid-area: left;
  padding: 48px 0 0 30px;
  width: 186px;
  background-color: #81817b; }

aside#left p {
  margin: 4px 0 8px; }

/* --- Main content ------------------------------------------------ */
main {
  grid-area: main;
  padding: 9px 14px 20px;
  min-height: 480px;
  background-color: #ffffff;
  line-height: 1.3; }

main a, main a:visited {
  color: #000000;
  text-decoration: underline; }

main a.home {
  display: inline-block;
  margin-bottom: 0.5em; }

main h1 {
  margin-top: 0; }

main p {
  margin: 1em 0; }

main img {
  max-width: 100%; }

/* --- Quote of the day -------------------------------------------- */
aside#quote {
  grid-area: quote;
  width: 212px;
  padding: 0 39px 0 0;
  background-color: #ffffff;
  background-image: url("../images/bg_right.png");
  background-position: right;
  background-repeat: repeat-y; }

aside#quote blockquote {
  margin-left: 10px;
  padding: 12px 6px 12px 14px;
  background-color: #ffc600;
  font-family: "Georgia", "Libre Baskerville";
  font-size: 13px;
  line-height: 1.3; }

/* --- Right sidebar content --------------------------------------- */
aside#right {
  grid-area: right;
  width: 212px;
  padding: 0 39px 26px 0;
  background-color: #ffffff;
  background-image: url("../images/bg_right.png");
  background-position: right;
  background-repeat: repeat-y; }

aside#right > label {
  color: #ffffff;
  background-color: #0094de; }

aside#right > label > span {
  overflow: hidden; }

aside#right h2 {
  margin: 0 0 11px;
  padding: 14px 6px 14px 15px;
  font-size: 14px;
  color: #ffffff;
  background-color: #0094de;
  font-weight: normal; }

aside#right p {
  margin: 0 4px 0 13px; }

aside#right a, aside#right a:visited {
  display: block;
  margin: 6px 2px 3px 0;
  text-align: right;
  color: #ff0000; }

/* --- Footer ------------------------------------------------------ */
footer {
  grid-area: footer;
  display: flex;
  padding: 0 30px;
  flex-direction: row;
  background-color: #535353;
  background-image: url("../images/bg-footer1.gif");
  background-position: top;
  background-repeat: repeat-x;
  color: #ffffff;
  font-size: 12px; }

#footer_nav {
  width: 100%;
  height: 76px;
  background-color: #B0B0B0;
  background-image: url("../images/bg-footer1.gif");
  background-position: top;
  background-repeat: repeat-x; }

#footer_nav ul {
  margin-top: 15px;
  justify-content: center; }

#footer_nav li::before {
  content: "|\a0"; }

#footer_nav li:first-child::before {
  content: ""; }

#footer_nav a, #footer_nav a:visited {
  color: #ffffff; }

#footer_nav li::after {
  content: "\a0"; }

/* === Accessibility helpers ======================================= */
/* Hide visually, but remain approachable for screenreader */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  white-space: nowrap;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0; }

/* Show bypass link on hover */
.element-focusable:focus {
  clip: auto;
  overflow: visible;
  height: auto; }

/* Sample styling for bypass link */
.bypass-to-main:focus {
  top: 0;
  left: 0;
  width: 100%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #333;
  color: #fff; }

/* --- Include responsive styles ----------------------------------- */
/* === Constants =================================================== */
/* === Media sizes ================================================= */
/* --- Large devices ----------------------------------------------- */
@media only screen and (min-width: 900px) {
  aside#right > label {
    display: none; }
  aside#right > .collapsible {
    max-height: none; } }

/* --- Large and medium devices ------------------------------------ */
/* --- Medium devices ---------------------------------------------- */
/* --- Medium and small devices ------------------------------------ */
@media only screen and (max-width: 899px) {
  html {
    font-size: 14px; }
  body {
    overflow-x: hidden; }
  #page {
    margin: 0;
    padding: 0;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto 1fr auto auto;
    grid-template-areas: "header" "quote" "right" "main" "left" "footer"; }
  header {
    margin: 0;
    padding: 0 0 16px;
    height: auto; }
  #logo {
    margin: 8px 0 0; }
  #header_nav {
    margin: 0;
    padding: 0 16px 8px;
    width: 100%;
    align-self: end;
    font-size: 15px; }
  #hamburger {
    margin: 8px 16px;
    justify-self: end; }
  aside#left, aside#quote, aside#right {
    margin: 0;
    padding: 0;
    width: 100%; }
  aside#left {
    display: flex;
    align-items: center;
    justify-items: baseline; }
  aside#left p {
    margin: 8px 16px; }
  main {
    margin: 0;
    padding: 16px;
    width: 100%; }
  aside#quote blockquote {
    margin: 0;
    padding: 16px;
    width: 100%;
    font-size: 15px; }
  aside#right {
    background-image: none;
    background-color: #ffffff;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1); }
  aside#right > label {
    display: block;
    padding: 16px 16px;
    font-size: 15px; }
  aside#right input.collapsible_toggle:checked + label > span {
    width: 1em; }
  aside#right .collapsible {
    border-bottom: 1px solid #c0c0c0; }
  aside#right h2 {
    margin: 0 0 16px;
    padding: 8px 16px 16px;
    font-size: 17px; }
  aside#right p {
    margin: 0px 16px 16px; }
  footer {
    margin: 0;
    padding: 0;
    width: 100%;
    font-size: 14px; } }

/* --- Small devices ----------------------------------------------- */
@media only screen and (max-width: 639px) {
  html {
    font-size: 16px; }
  h1 {
    font-size: 1.5rem; }
  header {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas: "logo hamburger" "header_nav header_nav"; }
  #logo img {
    width: 62vw; }
  aside#left {
    flex-direction: column; }
  aside#left p {
    margin: 8px 16px; }
  aside#quote blockquote {
    font-size: 17px; }
  aside#right > label {
    font-size: 17px; }
  aside#right h2 {
    font-size: 17px; }
  #footer_nav {
    background-color: #000000; }
  #footer_nav ul {
    margin: 24px 16px 16px;
    flex-direction: column;
    align-items: center; }
  #footer_nav li::before {
    content: ""; } }
