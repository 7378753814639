/* === Constants =================================================== */

/* === Media sizes ================================================= */

/* --- Large devices ----------------------------------------------- */
@media only screen and (min-width: $responsive-large-breakpoint) {

    aside#right > label {
        display: none;
    }
    
    aside#right > .collapsible {
        max-height: none;
    }
    
}

/* --- Large and medium devices ------------------------------------ */
@media only screen and (min-width: $responsive-medium-breakpoint) {
}

/* --- Medium devices ---------------------------------------------- */
@media only screen and (min-width: $responsive-medium-breakpoint) and (max-width: $responsive-large-breakpoint - 1px) {

}

/* --- Medium and small devices ------------------------------------ */
@media only screen and (max-width: $responsive-large-breakpoint - 1px) {

    html  {
        font-size: 14px;
    }

    body {
        overflow-x: hidden;
    }

    #page {
        margin: 0;
        padding: 0;
        width: 100%;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto 1fr auto auto;
        grid-template-areas:
            "header"
            "quote"
            "right"
            "main"
            "left"
            "footer";
    }

    header {
        margin: 0;
        padding: 0 0 16px;
        height: auto;
    }

    #logo {
        margin: 8px 0 0;
    }

    #header_nav {
        margin: 0;
        padding: 0 16px 8px;
        width: 100%;
        align-self: end;
        font-size: 15px;
    }

    #hamburger {
        margin: 8px 16px;
        justify-self: end;
    }

    aside#left, aside#quote, aside#right {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    aside#left {
        display: flex;
        align-items: center;
        justify-items: baseline;
    }
    
    aside#left p {
        margin: 8px 16px;
    }

    main {
        margin: 0;
        padding: 16px;
        width: 100%;
    }

    aside#quote blockquote {
        margin: 0;
        padding: 16px;
        width: 100%;
        font-size: 15px;
    }

    aside#right {
        background-image: none;
        background-color: #ffffff;
        box-shadow: 0 8px 12px  rgba(0, 0, 0, 0.1);
    }

    aside#right > label {
        display: block;
        padding: 16px 16px;
        font-size: 15px;
    }

    aside#right input.collapsible_toggle:checked + label > span {
        width: 1em;
    }

    aside#right .collapsible {
        border-bottom: 1px solid #c0c0c0;
    }

    aside#right h2 {
        margin: 0 0 16px;
        padding: 8px 16px 16px;
        font-size: 17px;
    }

    aside#right p {
        margin: 0px 16px 16px;
    }

    footer {
        margin: 0;
        padding: 0;
        width: 100%;
        font-size: 14px;
    }

}

/* --- Small devices ----------------------------------------------- */
@media only screen and (max-width: $responsive-medium-breakpoint - 1px) {
  
    html  {
        font-size: 16px;
    }

    h1 {
        font-size: 1.5rem;
    }

    header {
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        grid-template-areas: 
            "logo hamburger"
            "header_nav header_nav";
    }

    #logo img {
        width: 62vw;
    }

    aside#left {
        flex-direction: column;
    }
    
    aside#left p {
        margin: 8px 16px;
    }

    aside#quote blockquote {
        font-size: 17px;
    }

    aside#right > label {
        font-size: 17px;
    }

    aside#right h2 {
        font-size: 17px;
    }

    #footer_nav {
        background-color: #000000;
    }

    #footer_nav ul {
        margin: 24px 16px 16px;
        flex-direction: column;
        align-items: center;
    }

    #footer_nav li::before {
        content: "";
    }
}
